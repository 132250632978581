var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pl-4",
                  attrs: { cols: "7", lg: "7", md: "6", xl: "7" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "grey--text text--darken-4 text-h5" },
                    [_vm._v(" " + _vm._s(_vm.$t("userSettings")) + " ")]
                  ),
                ]
              ),
              _c("v-spacer"),
              _vm.onSuccess
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "ma-0",
                          attrs: {
                            type: "success",
                            dismissible: "",
                            "max-height": "250",
                            dense: "",
                            text: "",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.successMessage) + " "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasError
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "ma-0",
                          attrs: {
                            type: "error",
                            dismissible: "",
                            "max-height": "250",
                            dense: "",
                            text: "",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.onNotificationSuccess
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 mt-2 mr-4 pr-4",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "px-1 ma-0 text-subtitle-2 pr-2",
                          attrs: {
                            "max-height": "250",
                            dismissible: "",
                            dense: "",
                            text: "",
                            icon: "mdi-check-circle-outline",
                            color: "green darken-4",
                          },
                          model: {
                            value: _vm.onNotificationSuccess,
                            callback: function ($$v) {
                              _vm.onNotificationSuccess = $$v
                            },
                            expression: "onNotificationSuccess",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("notificationPreferenceAlert")) +
                              " "
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-row", [_c("v-divider")], 1),
      _c(
        "v-row",
        { staticClass: "fill-height" },
        [
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "4", md: "3" } },
                [
                  _c(
                    "v-navigation-drawer",
                    { attrs: { permanent: "" } },
                    [
                      _c(
                        "v-tabs",
                        { attrs: { vertical: "", flat: "" } },
                        _vm._l(_vm.listItems, function (item, index) {
                          return _c(
                            "v-tab",
                            {
                              key: item.label,
                              staticClass: "justify-start text-none",
                              staticStyle: { "min-width": "100%" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeSelectedTab(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { fullscreen: "" },
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pr-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("userSettings")) + " "
                              ),
                              _c("v-spacer"),
                              _vm.$vuetify.breakpoint.xsOnly
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", small: "", light: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _vm.onNotificationSuccess
                            ? _c(
                                "v-row",
                                { staticClass: "px-3 my-4 mt-2" },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass:
                                        "my-0 text-subtitle-2 ml-2 mr-0 pr-1 px-0",
                                      attrs: {
                                        dense: "",
                                        text: "",
                                        dismissible: "",
                                        icon: "mdi-check-circle-outline",
                                        color: "green darken-4",
                                      },
                                      model: {
                                        value: _vm.onNotificationSuccess,
                                        callback: function ($$v) {
                                          _vm.onNotificationSuccess = $$v
                                        },
                                        expression: "onNotificationSuccess",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notificationPreferenceAlert"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dialog
                            ? _c(
                                "v-navigation-drawer",
                                { attrs: { floating: "", permanent: "" } },
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: {
                                        vertical: "",
                                        flat: "",
                                        "slider-color": "transparent",
                                      },
                                    },
                                    _vm._l(
                                      _vm.listItems,
                                      function (item, index) {
                                        return _c(
                                          "v-tab",
                                          {
                                            key: item.label,
                                            staticClass:
                                              "justify-start text-none mobile-menu-items",
                                            attrs: {
                                              "active-class":
                                                "mobile-menu-items-active",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeSelectedTab(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(item.label)) +
                                                " "
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "9" } },
            [
              _c(
                "v-tabs-items",
                {
                  staticClass: "fill-height",
                  model: {
                    value: _vm.selectedTab,
                    callback: function ($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "tabitems",
                      attrs: { href: "#user-information" },
                    },
                    [
                      _c("UserInformation", {
                        on: {
                          "update-user": _vm.updateUserData,
                          backtoUserSetting: _vm.backtoUserSetting,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "tabitems",
                      attrs: { href: "#comany-information" },
                    },
                    [
                      _c("CompanyInformation", {
                        attrs: { companydata: _vm.comanyData },
                        on: {
                          "update:companydata": function ($event) {
                            _vm.comanyData = $event
                          },
                          backtoUserSetting: _vm.backtoUserSetting,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "tabitems",
                      attrs: { href: "#change-password" },
                    },
                    [
                      _c("ChangePassword", {
                        ref: "changePasswordComponent",
                        on: {
                          "change-password": _vm.changePassword,
                          backtoUserSetting: _vm.backtoUserSetting,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "tabitems",
                      attrs: { href: "#notification-preferences" },
                    },
                    [
                      _c("NotificationPreferences", {
                        on: {
                          backtoUserSetting: _vm.backtoUserSetting,
                          updateNotificationPreferences: function ($event) {
                            _vm.onNotificationSuccess = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }