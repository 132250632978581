<template>
  <v-container fluid class="pa-0">
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="7" lg="7" md="6" xl="7" class="pl-4">
        <span class="grey--text text--darken-4 text-h5">
          {{ $t("userSettings") }}
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" v-if="onSuccess">
        <v-alert
          type="success"
          dismissible
          max-height="250"
          dense
          text
          class="ma-0"
        >
          <span>
            {{ successMessage }}
          </span>
        </v-alert>
      </v-col>
      <v-col cols="auto" v-if="hasError">
        <v-alert
          type="error"
          dismissible
          max-height="250"
          dense
          text
          class="ma-0"
        >
          <span>
            {{ errorMessage }}
          </span>
        </v-alert>
      </v-col>

      <v-col
        v-if="onNotificationSuccess"
        cols="auto"
        class="pa-0 mt-2 mr-4 pr-4"
      >
        <v-alert
          v-model="onNotificationSuccess"
          max-height="250"
          dismissible
          dense
          text
          class="px-1 ma-0 text-subtitle-2 pr-2"
          icon="mdi-check-circle-outline"
          color="green darken-4"
        >
          {{ $t("notificationPreferenceAlert") }}
          <v-spacer></v-spacer>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row class="fill-height">
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="4" md="3" class="pa-0">
        <v-navigation-drawer permanent>
          <v-tabs vertical flat>
            <v-tab
              v-for="(item, index) in listItems"
              :key="item.label"
              class="justify-start text-none"
              style="min-width: 100%"
              @change="changeSelectedTab(index)"
            >
              {{ $t(item.label) }}
            </v-tab>
          </v-tabs>
        </v-navigation-drawer>
      </v-col>
      <v-row v-else>
        <v-dialog v-model="dialog" fullscreen>
          <v-card flat>
            <v-card-title class="pr-1">
              {{ $t("userSettings") }}
              <v-spacer></v-spacer>
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                text
                small
                light
                @click="$router.go(-1)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-row v-if="onNotificationSuccess" class="px-3 my-4 mt-2">
              <v-alert
                v-model="onNotificationSuccess"
                dense
                text
                dismissible
                icon="mdi-check-circle-outline"
                class="my-0 text-subtitle-2 ml-2 mr-0 pr-1 px-0"
                color="green darken-4"
              >
                {{ $t("notificationPreferenceAlert") }}
              </v-alert>
            </v-row>
            <v-navigation-drawer v-if="dialog" floating permanent>
              <v-tabs vertical flat slider-color="transparent">
                <v-tab
                  v-for="(item, index) in listItems"
                  :key="item.label"
                  active-class="mobile-menu-items-active"
                  class="justify-start text-none mobile-menu-items"
                  @click="changeSelectedTab(index)"
                >
                  {{ $t(item.label) }}
                  <v-spacer></v-spacer>
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-tab>
              </v-tabs>
            </v-navigation-drawer>
          </v-card>
        </v-dialog>
      </v-row>
      <v-col cols="12" sm="8" md="9">
        <v-tabs-items v-model="selectedTab" class="fill-height">
          <v-tab-item href="#user-information" class="tabitems">
            <UserInformation
              @update-user="updateUserData"
              @backtoUserSetting="backtoUserSetting"
            />
          </v-tab-item>
          <v-tab-item href="#comany-information" class="tabitems">
            <CompanyInformation
              :companydata.sync="comanyData"
              @backtoUserSetting="backtoUserSetting"
            />
          </v-tab-item>
          <v-tab-item href="#change-password" class="tabitems">
            <ChangePassword
              ref="changePasswordComponent"
              @change-password="changePassword"
              @backtoUserSetting="backtoUserSetting"
            />
          </v-tab-item>
          <v-tab-item href="#notification-preferences" class="tabitems">
            <NotificationPreferences
              @backtoUserSetting="backtoUserSetting"
              @updateNotificationPreferences="onNotificationSuccess = true"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserSettings",
  components: {
    UserInformation: () => import("./UserInformation.vue"),
    CompanyInformation: () => import("./CompanyInformation.vue"),
    NotificationPreferences: () => import("./NotificationPreferences.vue"),
    ChangePassword: () => import("./ChangePassword.vue"),
  },
  data: () => ({
    selectedTab: 0,
    dialog: true,
    onSuccess: false,
    hasError: false,
    successMessage: "",
    errorMessage: "",
    onNotificationSuccess: false,
  }),
  async mounted() {
    if (this.$vuetify.breakpoint.smAndUp) {
      this.selectedTab = 0;
    }
  },
  computed: {
    comanyData() {
      return this.$store.state.Company.company;
    },
    listItems() {
      if (this.$store.state.User.user.persona === "sysop")
        return [
          { label: "userInformation", tab: "user-information" },
          { label: "companyInformation", tab: "comany-information" },
          { label: "changePasswordHeader", tab: "change-password" },
        ];
      else
        return [
          { label: "userInformation", tab: "user-information" },
          { label: "companyInformation", tab: "comany-information" },
          { label: "changePasswordHeader", tab: "change-password" },
          {
            label: "notificationPreference",
            tab: "notification-preferences",
          },
          // { label: "Excavation Details Defaults", tab: "excavation-details-defaults" },
        ];
    },
  },
  watch: {
    onNotificationSuccess() {
      if (this.onNotificationSuccess === true) {
        setTimeout(() => {
          this.onNotificationSuccess = false;
        }, 3000);
      }
    },
    onSuccess() {
      if (this.onSuccess) {
        setTimeout(() => {
          this.onSuccess = false;
        }, 3000);
      }
    },
    hasError() {
      if (this.hasError) {
        setTimeout(() => {
          this.hasError = false;
        }, 3000);
      }
    },
  },
  methods: {
    backtoUserSetting() {
      this.dialog = true;
    },
    changeSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.$vuetify.breakpoint.xsOnly) {
        this.dialog = false;
      }
    },
    async updateUserData(data) {
      this.errorMessage = "";
      this.successMessage = "";
      this.onSuccess = false;
      this.hasError = false;

      const result = await this.$store.dispatch("updateUser", {
        id: data.userId,
        name: data.name,
        phone: data.phone,
        email: data.email,
      });

      if (result.error) {
        this.hasError = true;
        this.errorMessage = result.error;
      } else {
        this.onSuccess = true;
        this.successMessage = "User information updated successfully!";
      }
      return result;
    },
    async changePassword(data) {
      this.hasError = false;
      this.onSuccess = false;
      this.errorMessage = "";
      this.successMessage = "";
      const result = await this.$store.dispatch("changePassword", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      if (result.error) {
        this.hasError = true;
        this.errorMessage = result.error;
      } else {
        this.onSuccess = true;
        this.successMessage = "Password updated successfully!";
      }

      this.$refs.changePasswordComponent.resetForm();
      return result;
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer.v-navigation-drawer--open {
  width: 100%;
  min-width: 100%;
  min-height: calc(100vh - 64px);
}
.mobile-menu-items {
  min-width: 100%;
}
.mobile-menu-items-active {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
